import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"

import ImageMeta from "../../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

function ImageTextAOMS(props) {
  return (
    <section className="home-section color-back text-picture sands-banner">

<div className="columns is-vcentered">
  <div className="column is-5"></div>
  <div className="column is-5">
  <ImageMeta
                        cloudName="nuvolum"
                        publicId="OSSC/DEV/sands-castle-logo.svg"
                        width="auto"
                        responsive
                        />
  </div>
  <div className="column">
    <h3>S.A.N.D.S.</h3>
    <h3>Special Needs Network of&nbsp;Dentists</h3>
  </div>
  <div className="column is-3"></div>
</div>

<div className="columns">
  <div className="column is-5"></div>
  <div className="column">
    <p>Oral Surgery & Dental Implant Specialists South Carolina is listed in the S.A.N.D.S. directory as a dental practice comfortable providing care for patients with disabilities. It's an honor to care for others, and we're proud to extend our dental services to special needs&nbsp;patients.</p>
  </div>
  <div className="column is-5"></div>
</div>

<div className="columns">
  <div className="column is-5"></div>
  <div className="column">
  <ButtonGroup isCentered>
                          <Button
                            buttonText="Learn More"
                            href="/sands/"
                          />
                          
                        </ButtonGroup>
  </div>
  <div className="column is-5"></div>
</div>


  </section>
  
  )
}

export default ImageTextAOMS
