import React from "react"
import ImageMeta from "../ImageMeta"

const FeaturedPublications = () => {
  return (
    <section data-aos="fade-up" data-aos-duration="1200">
      <div className="columns featured-publications">
        <div className="column">
          <h3 className="has-text-centered">Featured In</h3>

          <div className="publications-grid">
            <p>
              Dr. Barefoot was recognized as the Best Oral Surgeon by
              HealthLinks Charleston Magazine, and our practice was featured in
              Charleston Living Magazine's 'A' List as an exceptional business.
            </p>

            <div className="publication-images">
              <a
                aria-label="open link in new browser tab"
                href="https://www.charlestonphysicians.com/healthlinks/mayjune2021/46/"
                target="_blank"
                title="Read more about us in the May/June issue of HealthLinks"
              >
                <ImageMeta
                  publicId="OSSC/DEV/homepage-health-links-magazine"
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                  className="mp-badge"
                />
              </a>
              <a
                aria-label="open link in new browser tab"
                href="https://charlestonlivingmag.com/the-%E2%80%98a%E2%80%99-list"
                target="_blank"
                title="Read more about us in the Charleston Living magazine"
              >
                <ImageMeta
                  publicId="OSSC/DEV/homepage-charleston-living-magazine"
                  cloudName="nuvolum"
                  width="auto"
                  responsive
                  responsiveUseBreakpoints="true"
                  className="mp-badge"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedPublications
