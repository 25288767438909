import React from "react"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"

function WeValue({ colorBack=false, style }) {

  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-inverse"
        style={{backgroundColor: colorBack ? "" : "white"}}
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={1}
        leftColumn={
            <div className="column community-text-block">
              <h3>We Value Our Referral Network</h3>
              <p className="large">Dr. Barefoot and his team understand the amount of trust that other offices must have in order to refer patients to another provider. It's an honor to work alongside the remarkable dental professionals in our community. We enforce the same high standards to every patient and work hand-in-hand with referring doctors to ensure every patient achieves optimal clinical results. </p>
              {/* <ButtonGroupMap buttons={props.data.buttons} /> */}
              <a className="standard-button" href="/patient-stories/">Learn More</a>
            </div>
          }
        rightColumn={
          <div className="column is-8">
            <ImageMeta
              cloudName="nuvolum"
              publicId="OSSC/DEV/homepage-referral-network"
              responsive
            />
          </div>
        }
      />
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-block"
        style={{backgroundColor: "white"}}
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        rightColumn={
          <div className="column">
              <h3>We Value Our Referral Network</h3>
              <p className="large">Dr. Barefoot and his team understand the amount of trust that other offices must have in order to refer patients to another provider. It's an honor to work alongside the remarkable dental professionals in our community. We enforce the same high standards to every patient and work hand-in-hand with referring doctors to ensure every patient achieves optimal clinical results.</p>
              <ImageMeta
              cloudName="nuvolum"
              publicId="OSSC/DEV/homepage-referral-network"
              responsive
            />
            <div className="has-text-centered-mobile"><a className="standard-button" href="/patient-stories/">Learn More</a></div>
            {/* <ButtonGroupMap buttons={props.data.buttons} /> */}
          </div>
        }
        leftColumn={
          <div></div>
        }
      />
    </>
  )
}

export default WeValue
