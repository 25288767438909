import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import { VideoWrapped } from "../components/reactPlayerTest"
import ResponsivePlayer from "../components/ResponsivePlayer"
import "../components/animations/scroll.scss"
import ParallaxScroll from "../components/animations/scroll"
import ScrollCTA from "../components/animations/lottie/scrollCTA"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import CursorOverContainer from "../components/cursor/CursorOverContainer"
import Sizzle from "./index/Sizzle"
import StaticHeading from "./index/StaticHeading"
import Carousel from "../components/carousel/comFeaturedCarousel"
import WhyChoose from "./index/WhyChoose"
import WeValue from "./index/WeValue"
import Services from "./index/Services"
import ImageTextSANDS from "./index/ImageTextSANDS"
import InTheCommunity from "./index/InTheCommunity"
import { BestMtPleasant, FeaturedPublications } from "../components/banners"

import TimedElement from "@bit/azheng.joshua-tree.timed-element"
import NewToast from "@bit/azheng.joshua-tree.new-toast"

import fbRecommendations from "../images/fb-recommendations.svg"
import Stars from "../components/SocialReviews/Stars"
import ImageMeta from "../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

class IndexPage extends Component {
  constructor() {
    super()
    this.state = {
      didScroll: false,
      scrollPosition: "relative",
      scrollOverflow: "unset",
      playing: false,
      extTheater: false,
      toggleCarouselsClass: false
    }
    this.toggleCarousel = this.toggleCarousel.bind(this)
    // this.window = window;

    // this.overrideTheater = this.overrideTheater.bind(this);
  }

  toggleCarousel() {
    var that = this
    that.setState({ toggleCarouselsClass: true })
    window.removeEventListener(
      "aos:in:home-section",
      this.toggleCarousel,
      false
    )
    window.removeEventListener(
      "aos:in:featured-carousel",
      this.toggleCarousel,
      false
    )
    window.removeEventListener("scroll", this.toggleCarousel, false)
  }

  componentDidMount() {
    //document.body.style.height = "102vh";
    //document.body.style.overflow = "scroll !important";
    window.addEventListener("aos:in:home-section", this.toggleCarousel)
    window.addEventListener("aos:in:featured-carousel", this.toggleCarousel)
    window.addEventListener("scroll", this.toggleCarousel)
  }

  render() {
    const google = this.props.data.allGoogleJson.nodes[0].data
    const facebook = this.props.data.allFacebookJson.nodes[0].data
    const drBarefoot = this.props.data.drBarefootJson.data

    const post = this.props.data.allUniquePagesJson.nodes[0]

    var staticStyle = {
      position: this.state.scrollPosition,
      overflow: this.state.scrollOverflow,
      marginTop: "88px",
      width: "100%",
      zIndex: 1
    }
    var sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }
    var opStyle = {
      opacity: 0
    }

    return (
      <SharedStateProvider>
        <Layout
          layoutClass={"homepage-fab"}
          noFab={true}
          pageTitle={"main-homepage"}
          toastText={null}
        >
          <SEO
            title={post.metaTitle}
            description={post.metaDescription}
            schemaPage=""
          />

          <div className="static-container" style={staticStyle}>
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              post={post}
            >
              <StaticHeading
                playing={this.state.playing}
                sizzleButtonStyle={sizzleButtonStyle}
                post={post}
              />
            </Sizzle>

            <section className="home-section color-back text-picture home-section-1">
              <div className="columns reverse-columns">
                <div className="column is-2"></div>
                <div className="column is-10 picture-column">
                  <div className="columns">
                    <div className="column mobile-hide">
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={post.homeSectionOne.imageId}
                        width="auto"
                        responsive
                        data-aos-duration="1200"
                        data-aos="fade-left"
                        className="full-width"
                        data-aos-anchor-placement="center-bottom"
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-1"></div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="200"
                  id="first-column"
                  className="column is-9 text-column mobile-pad"
                >
                  <div className="columns">
                    <div className="column">
                      <div
                        dangerouslySetInnerHTML={createHtml(
                          converter.makeHtml(post.homeSectionOne.heading)
                        )}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={createHtml(
                          converter.makeHtml(post.homeSectionOne.text)
                        )}
                      ></div>
                      <div className="button-group-wrapper">
                        <div className="button-group align-right reverse-buttons">
                          <Button
                            buttonText={
                              post.homeSectionOne.buttonOne.buttonText
                            }
                            href={post.homeSectionOne.buttonOne.href}
                          />
                          <Button
                            buttonText={
                              post.homeSectionOne.buttonTwo.buttonText
                            }
                            href={post.homeSectionOne.buttonTwo.href}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="column is-2"></div>
              </div>
            </section>
            <div id="homeCar">
              <section className="home-section short-home">
                <div
                  className="container"
                  data-aos="fade-up"
                  data-aos-id="home-section"
                  data-aos-duration="1200"
                >
                  <div className="columns has-text-centered">
                    <div className="column">
                      <h3>Hear From Our Patients</h3>
                    </div>
                  </div>
                </div>
              </section>
              <div
                className={
                  "carousel-section-match" +
                  " carousel-" +
                  this.state.toggleCarouselsClass
                }
              >
                <Carousel
                  slideID="featuredCarousel"
                  data-aos="no-anim"
                  data-aos-offset="400"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="0"
                  data-aos-id="featured-carousel"
                />
              </div>
            </div>

            <WhyChoose />

            <InTheCommunity data={post.inTheCommunity} />

            <WeValue />

            <Services post={post.services} />

            <ImageTextSANDS />

            {/* <section className="home-section">
        <div className="">
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
              <h3 style={{marginBottom: "36px"}}>Our Focus On Patient Experience</h3>
            </div>
          </div>
          <div className="columns" >
            <div className="column is-1"></div>
            <div className="column">
            <TheaterVideo 
              videoUrl="https://www.youtube.com/watch?v=8qPhV8e54bo"
              controls={true}
              playing={true}
              overVideo={true}
              hasWatchVideo={true}
              compId={2}
              >
              <ImageMeta
                cloudName="nuvolum"
                publicId="OSSC/DEV/patient-experience-focus.jpg"
                responsive
              >
                
              </ImageMeta>
              </TheaterVideo> 
            </div>
            <div className="column is-1"></div>
          </div>
        </div>
      </section>
   */}

            <section data-aos="fade-up" data-aos-duration="1200">
              <BestMtPleasant />
            </section>

            <FeaturedPublications />

            <section
              className="home-section color-back"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="container ">
                <div className="columns">
                  <div className="column is-4"></div>
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <h3 style={{ marginBottom: "16px" }}>
                          {post.firstTimePatient.heading}
                        </h3>
                      </div>
                    </div>
                    <div className="columns">
                      <div
                        className="column"
                        dangerouslySetInnerHTML={createHtml(
                          converter.makeHtml(post.firstTimePatient.text)
                        )}
                      ></div>
                    </div>
                    <div className="columns has-text-centered">
                      <div className="column">
                        <ButtonGroup isCentered>
                          <Button
                            buttonText={
                              post.firstTimePatient.buttonOne.buttonText
                            }
                            href={post.firstTimePatient.buttonOne.href}
                          />
                          <Button
                            buttonText={
                              post.firstTimePatient.buttonTwo.buttonText
                            }
                            href={post.firstTimePatient.buttonTwo.href}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="column is-4"></div>
                </div>
              </div>
            </section>
          </div>

          <section className="home-section ratings-review">
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="columns has-text-centered">
                <div className="column">
                  <h3>{post.homeRatingsReviews.heading}</h3>
                  <div
                    dangerouslySetInnerHTML={createHtml(
                      converter.makeHtml(post.homeRatingsReviews.text)
                    )}
                  ></div>
                </div>
              </div>
              <div className="columns">
                <div className="column"></div>
                <div className="column is-14">
                  <div className="rating-reviews home-reviews">
                    <div className="featured-social">
                      <a
                        aria-label="open link in new browser tab"
                        title="View all or our reviews"
                        className="link-wrapper"
                        href={google.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h3>Google</h3>
                      </a>
                      <a
                        aria-label="open link in new browser tab"
                        title="View all or our reviews"
                        className="link-wrapper"
                        href={google.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="review-icons">
                          <h4 className="google-rating">
                            {google.rating.toFixed(1)}
                          </h4>
                          <Stars rating={google.rating} />
                        </div>
                      </a>
                      <a
                        aria-label="open link in new browser tab"
                        title="View all or our reviews"
                        href={google.url}
                        target="_blank"
                      >
                        {google.totalReviews} Reviews
                      </a>
                    </div>
                    <div className="featured-social">
                      <a
                        aria-label="open link in new browser tab"
                        title="View all or our reviews"
                        className="link-wrapper"
                        href={facebook.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h3>Facebook</h3>
                      </a>
                      <a
                        aria-label="open link in new browser tab"
                        title="View all or our reviews"
                        className="link-wrapper"
                        href={facebook.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="review-icons">
                          <div className="svg-wrapper">
                            <img
                              src={fbRecommendations}
                              alt="Facebook Recommendations"
                            />
                          </div>
                        </div>
                      </a>
                      <a
                        aria-label="open link in new browser tab"
                        title="View all or our reviews"
                        href={facebook.url}
                        target="_blank"
                      >
                        {facebook.totalReviews} Reviews
                      </a>
                    </div>
                  </div>

                  <div className="columns has-text-centered">
                    <div className="column">
                      <Button
                        buttonText="More Reviews"
                        href="/patient-stories/"
                      />
                    </div>
                  </div>
                </div>
                <div className="column"></div>
              </div>
            </div>
          </section>
        </Layout>
      </SharedStateProvider>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery {
    drBarefootJson {
      data {
        rating
        totalReviews
        url
      }
    }
    allGoogleJson {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    allFacebookJson {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        mobileHeading
        homeSectionOne {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          imageId
          text
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
          hasThisSection
          imageId
          leftRight
          subheading
          text
        }
        services {
          blurb
          hasThisSection
          heading
          items {
            blurb
            heading
            imageId
            buttons {
              button {
                buttonText
                appearance
                buttonText
                href
                destination
              }
            }
          }
          buttons {
            button {
              buttonText
              appearance
              buttonText
              href
              destination
            }
          }
        }
        homeRatingsReviews {
          heading
          text
        }
        seoHeading
        firstTimePatient {
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
          text
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
        svgHeading
        svgSubheading
      }
    }
  }
`

export default IndexPage
