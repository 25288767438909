import React from "react"
import PropTypes from "prop-types"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import MarkdownViewer from "../../components/MarkdownViewer"

import "./InTheCommunity.scss"

function InTheCommunity(props) {
  if (!props.data.hasThisSection) {
    return <div></div>
  }

  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-inverse"
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={1}
        leftColumn={
          <div className="column">
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.data.imageId}
              responsive
            />
          </div>
        }
        rightColumn={
          <div className="column is-8 community-text-block">
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <MarkdownViewer markdown={props.data.text} className="large-p" />
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
      />
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-block"
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        leftColumn={
          <div className="column">
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <MarkdownViewer markdown={props.data.text} className="large-p" />
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.data.imageId}
              responsive
            />
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
        rightColumn={<div></div>}
      />
    </>
  )
}

export default InTheCommunity
