import React from "react"
import PropTypes from "prop-types"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import MarkdownViewer from "../../components/MarkdownViewer"

function WhyChoose({ colorBack, style }) {

  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-inverse"
        style={{backgroundColor: colorBack ? "" : "white"}}
        leftColWidth={3}
        rightColWidth={3}
        middleColWidth={1}
        leftColumn={
            <div className="column community-text-block">
              <h3>Why Choose an Oral Surgeon?</h3>
              <p className="large">An oral surgeon like Dr. Barefoot undergoes extensive training to perform complex surgeries and restorative procedures to the head, neck, and face, and is qualified to administer anesthesia safely.</p>
              {/* <ButtonGroupMap buttons={props.data.buttons} /> */}
              <a className="standard-button" href="/why-choose-an-oral-surgeon/">Learn More</a>
            </div>
          }
        rightColumn={
          <div className="column is-8">
            <ImageMeta
              cloudName="nuvolum"
              publicId="OSSC/DEV/why-choose-os-image"
              responsive
            />
          </div>
        }
      />
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-block"
        style={{backgroundColor: "white"}}
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        leftColumn={
          <div className="column">
              <h3>Why Choose an Oral Surgeon?</h3>
              <p className="large">An oral surgeon like Dr. Barefoot undergoes extensive training to perform complex surgeries and restorative procedures to the head, neck, and face, and is qualified to administer anesthesia safely.</p>
              <ImageMeta
              cloudName="nuvolum"
              publicId="OSSC/DEV/why-choose-os-image"
              responsive
            />
            <div className="has-text-centered-mobile"><a className="standard-button" href="/why-choose-an-oral-surgeon/">Learn More</a></div>
            {/* <ButtonGroupMap buttons={props.data.buttons} /> */}
          </div>
        }
        rightColumn={
          <div></div>
        }
      />
    </>
  )
}

export default WhyChoose
